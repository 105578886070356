<template>
  <div
    class="partner-list-wrapper"
    data-cy="partner-list-wrapper"
  >
    <div class="partner-controls">
      <c-title-progress
        data-cy="progress-bar"
        title="Parceiros ativos / disponíveis"
        :active="activePartners"
        :total="totalPartners"
        :percentage="countPercentage"
      />

      <div
        class="header-actions"
        v-if="isDesktop"
      >
        <div
          class="wrapper-search-field"
          v-if="isDesktop"
        >
          <search-field
            data-cy="search-partner-input"
            :term="searchTerm"
            @reset="$emit('clear-search')"
            @input="name => $emit('update-filters', { name })"
            @submit="$emit('search-partners')"
            @focusout="searchFieldAnalytics()"
            placeholder="Encontre um parceiro"
          />
        </div>

        <c-button
          data-cy="show-orders-toggle-button"
          after
          size="lg"
          icon="chevron-down"
          icon-size="sm"
          ref="orderToggle"
          @click.native="showOrder = !showOrder"
        >
          Ordenar
        </c-button>

        <c-button
          data-cy="show-filters-toggle-button"
          size="lg"
          icon-size="sm"
          after
          :icon="isDesktop ? 'chevron-down' : ''"
          ref="filterToggle"
          @click.native="showFilter = !showFilter"
        >
          Filtrar
        </c-button>
      </div>

      <div class="header-popovers">
        <order-popover
          :show="showOrder"
          :ordinators="ordinators"
          :target="$refs['orderToggle']"
          :params="params"
          @handleOrder="handleOrder($event)"
          @apply-filters="applyOrderFilter()"
          @change-show="showOrder = $event"
          @clean="clean($event)"
        />

        <filter-popover
          :show="showFilter"
          :categories="categories"
          :target="$refs['filterToggle']"
          :params="params"
          :status="status"
          @handleCategory="handleCategory"
          @handleStatus="handleStatus"
          @change-show="showFilter = $event"
          @apply-filters="applyFilters()"
          @clean="clean($event)"
        />
      </div>

      <div
        class="wrapper-filter-toggle"
        v-if="!isDesktop"
      >
        <div class="buttons-group">
          <c-button
            data-cy="new-partner-button"
            @click="$router.push({ name: 'parceiros-criar' })"
            v-if="isExclusives"
            icon="plus"
            size="lg"
            icon-size="sm"
            primary
          >
            Novo parceiro
          </c-button>
          <c-button
            size="lg"
            :icon="isDesktop ? 'chevron-down' : ''"
            icon-size="sm"
            after
            @click.native="$emit('open-filters')"
          >
            Filtrar
          </c-button>
        </div>
        <search-field
          class="field-search"
          :term="searchTerm"
          @reset="resetSearch()"
          @input="name => $emit('update-filters', { name })"
          @submit="$emit('search-partners')"
          placeholder="Encontre um parceiro"
        />
      </div>
    </div>

    <div class="filter-tags">
      <c-button
        v-if="filtersComputed.length > 1"
        external
        size="md"
        icon="trash"
        class="clear-all"
        @click="clean(1)"
      >
        limpar tudo
      </c-button>
      <filter-tag
        v-for="(tag, index) in filtersComputed"
        :key="index"
        :filter="tag"
        @remove-filter="removeFilter(tag.id)"
      />
    </div>

    <div v-if="partners.length === 0 && !insearch && isExclusives">
      <c-empty
        class="empty"
        character="searching"
        title="Você ainda não cadastrou nenhum parceiro exclusivo!"
        message="O que acha de adicionar o primeiro parceiro exclusivo do clube?"
        btn-active
        btn-icon="plus"
        button-message="Novo Parceiro"
        to="parceiros-criar"
      />
    </div>

    <transition-group
      v-else-if="partners.length > 0"
      data-cy="partners-list"
      tag="div"
      class="partner-list"
      name="partner-list"
      appear
    >
      <partner-card
        v-for="(partner, index) in partners"
        class="item"
        :key="`partner-card__${partner.slug}-${index}`"
        :logo="partner.logo"
        :name="partner.name"
        :slug="partner.slug"
        :status="isExclusives ? !!partner.status : !!partner.is_blocked"
        :category="getCategory(partner)"
        :is-exclusive="isExclusives"
        :campaign-count="partner.campaignsCount"
        :is-loading="lockPartner === partner.slug"
        @toggle-partner-status="$emit('toggle-partner-status', $event)"
        @click.native="goToCampaigns(partner.slug)"
      />
    </transition-group>

    <c-empty
      v-else
      class="empty"
      title="Ops!"
      character="searching"
      message="Não encontramos nenhum parceiro relacionado à busca"
    />

    <div class="partner-pagination">
      <c-spinner
        v-if="paginationLoading"
        size="md"
      />
      <c-button
        @click="$emit('paginate-list')"
        v-else-if="showPagination"
        size="lg"
      >
        Ver Mais
      </c-button>
    </div>
  </div>
</template>

<script>
import SearchField from '@/components/CComponents/SearchField'
import CTitleProgress from '@/components/CComponents/CTitleProgress.vue'

import PartnerCard from '@/components/Partners/Listing/PartnerCard'

import OrderPopover from './OrderPopover'
import FilterPopover from './FilterPopover'

import ordinators from '@/store/filters/ordinators.json'
import filters from '@/store/filters/filters.json'
import * as object from '@/modules/object'

import { mapGetters } from 'vuex'
import * as types from '@/store/types'
import FilterTag from '@/components/Filter/FilterTag.vue'

export default {
  components: {
    SearchField,
    CTitleProgress,
    OrderPopover,
    FilterPopover,
    PartnerCard,
    FilterTag
  },

  props: {
    partners: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    isExclusives: {
      type: Boolean,
      required: true
    },
    searchTerm: {
      type: String,
      required: false
    },
    partnerCount: {
      type: Object,
      required: true
    },
    paginationLoading: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      required: true
    },
    insearch: {
      type: Boolean,
      required: true
    },
    lockPartner: {
      type: String,
      required: false
    }
  },
  data () {
    const getActions = (filters) => {
      const actions = filters.map((_) => _.action)
      return object.reduce(actions, (_, property) => ({ [property]: false }))
    }

    const params = {
      ...getActions(filters.categories),
      ...getActions(filters.status),
      ...getActions(ordinators)
    }
    return {
      displayStatusConfirmation: false,
      showFilter: false,
      showOrder: false,
      categories: [...filters.categories],
      status: [...filters.status],
      ordinators: [...ordinators],
      params
    }
  },
  computed: {
    ...mapGetters({ company: types.COMPANY }),

    filtersComputed () {
      if (this.filters.categories) {
        const filtersFormated = this.filters.categories.flatMap(category => {
          const filteredCategory = filters.categories.filter(
            filterCategory => filterCategory.action === category
          )
          return filteredCategory.map(mappedCategory => ({
            id: mappedCategory.action,
            label: mappedCategory.name
          }))
        })

        const status = this.filters.status === '1'
          ? { id: 1, label: 'Ativo' }
          : { id: 0, label: 'Inativo' }

        const uniqueFilters = [
          ...filtersFormated,
          this.filters.status !== '' ? status : null
        ]
          .filter(Boolean)
          .reduce((acc, current) => {
            if (!acc.some(item => item.id === current.id)) {
              acc.push(current)
            }
            return acc
          }, [])

        return uniqueFilters
      } else {
        return []
      }
    },

    activePartners () {
      return this.isExclusives
        ? this.partnerCount.exclusive_enabled
        : this.partnerCount.public_available
    },
    totalPartners () {
      return this.isExclusives
        ? this.partnerCount.exclusive_total
        : this.partnerCount.total
    },
    countPercentage () {
      const active = this.activePartners || 0
      const total = this.totalPartners || 1
      return ((active * 100) / total) + ''
    },
    showPagination () {
      return this.partners.length < this.pagination.total
    }
  },
  methods: {
    saveInLocalStorage () {
      const savedFilters = localStorage.getItem('partnerFilters')
      const parsedFilters = JSON.parse(savedFilters)
      const filters = {
        ...this.filters,
        ...parsedFilters
      }
      localStorage.setItem('partnerFilters', JSON.stringify(filters))
    },

    removeFilter (filterId) {
      if (typeof filterId === 'number') {
        this.$emit('update-filters', { status: '' })
      } else {
        const categoryIndex = this.filters.categories.findIndex(category => filterId === category)
        const newCategories = this.filters.categories
        newCategories.splice(categoryIndex, 1)
        this.$emit('update-filters', { categories: newCategories })
      }
      this.$emit('apply-filters')
    },

    searchFieldAnalytics () {
      this.handleAnalytics(this.setHandleAnalyticsEvent('buscar-parceiro'), {
        company_name: this.company.name,
        searched_term: this.searchTerm
      })
    },
    applyFilters () {
      this.$emit('apply-filters')
      this.handleAnalytics(this.setHandleAnalyticsEvent('filtrar-parceiro'), {
        company_name: this.company.name
      })
    },
    applyOrderFilter () {
      this.$emit('apply-filters')
      this.handleAnalytics(this.setHandleAnalyticsEvent('ordenar-parceiro'), {
        company_name: this.company.name
      })
    },
    setHandleAnalyticsEvent (event) {
      return this.$route.name === 'parceiros-geral' ? event + '-geral' : event
    },
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    getCategory (partner) {
      const { categories } = partner || {}
      return categories && categories.length
        ? categories[0].name
        : ''
    },
    goToCampaigns (slug) {
      this.$router.push({ name: 'parceiros-slug-campanhas', params: { slug } })
    },
    clean (type) {
      if (type === 'order') {
        this.params.asc = false
        this.params.desc = false
      } else {
        const actions = [...this.categories, ...this.status].map((_) => _.action)

        const params = object.reduce(this.params, (action, value) => ({
          [action]: actions.includes(action) ? false : value
        }))

        this.params = params
      }
      this.$emit('clear-filters', type)
    },
    handleOrder (order) {
      order === 'asc'
        ? this.params.desc = false
        : this.params.asc = false

      const isSelected = !this.params[order]

      this.params[order] = isSelected

      const ordinators = { asc: null, desc: null, exclusives: this.isExclusives }
      ordinators[order] = isSelected ? 'name' : null
      this.$emit('update-filters', ordinators)
    },
    handleCategory (action) {
      const value = !this.params[action]
      const categories = value
        ? [...this.filters.categories, action]
        : [...this.filters.categories.filter(c => c !== action)]

      this.$emit('update-filters', { categories })
      this.params[action] = value
    },
    handleStatus (status) {
      status === '0'
        ? this.params[1] = false
        : this.params[0] = false

      const isSelected = !this.params[status]

      this.params[status] = isSelected
      this.$emit('update-filters', { status: isSelected ? status : '' })
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$partner-list-spacing: 15px;

.filter-tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1020px;
  width: 100%;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  & > .clear-all {
    margin-right: 8px;
  }
}

.partner-controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 30px 0 0 0;
  padding: 0 15px;

  @include desktop {
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    padding: 0;
    @include container;
  }

  .header-actions {
    margin-left: 30px;
    display: flex;
    align-items: center;
    order: 1;

    @include mobile { padding-left: 10px; }

    .c-button:not(:first-child) { margin-left: 10px; }
  }

  .header-popovers > .c-popover > .content > .filters { @include popoverFilter; }

  .popover-filter-group { @include popoverFilterGroup; }

  .popover-filter-apply.c-button.-block { position: absolute; }

  .wrapper-search-field {
    display: flex;
    align-items: center;
    justify-content: center;

    .search-field > .wrapper-c-input,
    .search-field > .wrapper-c-input > .c-input-container {
      margin: 0 !important;
      min-width: 230px;
    }
  }

  .wrapper-filter-toggle {
    display: flex;
    flex-direction: column;
    margin: 20px -5px 10px -5px;

    & > .buttons-group {
      display: flex;

      .c-button { width: 100%; }
      & > .c-button:not(:last-child) { margin-right: 10px; }
    }

    & > .field-search { margin-top: 10px; width: 100%; }
  }
}

.partner-list {
  @include list;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 0px 0px 10px;

  @media only screen and (min-width: 650px) {
    padding: 10px 0 0 10px !important;
  }

  @media only screen and (max-width: 649px) {
    padding: 10px 5px 0 10px !important;
  }

  @media only screen and (min-width: 769px) {
    padding: 10px 5px 0 5px !important;
  }

  @include mobile { padding: 15px 10px; }

  & > .item {
    margin-right: 10px;

    @media only screen and (min-width: 549px) and (max-width: 649px) {
      flex-grow: 1;
    }

    @include mobile { margin-right: 5px; }
  }

  @media screen and (min-width: 645px) and (max-width: 768px) {
    & > .item { flex: calc(100% / 2 - 20px / 2) 0 0; }
    & > .item:nth-child(odd) { margin-left: 0; margin-right: 10px !important; }
  }

  @media screen and (min-width: 550px) and (max-width: 768px) {
    & > .item { flex: calc(100% / 2 - 20px / 2) 0 0; }
    & > .item:nth-child(odd) { margin-left: 0; margin-right: 5px; }
  }

  @media screen and (min-width: 769px) and (max-width: 992px) {
    & > .item { flex: calc(100% / 4 - 40px / 4) 0 0; }
    & > .item:nth-child(even) { margin-left: 0; margin-right: 5px; }
  }

  @media screen and (min-width: 993px) {
    & > .item { flex: calc(100% / 6 - 60px / 6) 0 0; }
    & > .item:nth-child(even) { margin-left: 0; margin-right: 5px; }
  }
}

.partner-pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @include desktop { @include container; }
}

// List Transition
// ---------------

.partner-list > .item {
  transition: transform .2s ease-out,
              opacity .3s ease-in;
}

.partner-list-enter,
.partner-list-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.partner-list-leave-active {
  position: absolute;
}
</style>
